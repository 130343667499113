import i18next from "i18next";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border-right: 1px solid #bebebe;
  padding: 16px 0;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  font-weight: 400;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 13px;
  margin: 0 16px;
`;
const Items = styled.div`
  flex-grow: 1;
`;
const DateButton = styled.div`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background: #ececec;
  }
`;
const ClearButton = styled.div`
  cursor: pointer;
  color: #7d7d7d;
  padding: 4px;

  &:hover {
    background: #ececec;
  }
`;

interface Props {
  clearable: boolean;
  lastMonthShortcut?: boolean;
  nextMonthShortcut?: boolean;
  handleClickTodayButton: () => void;
  handleClickEndOfMonthButton: () => void;
  handleClickEndOfLastMonthButton: () => void;
  handleClickEndOfNextMonthButton: () => void;
  handleClickClearButton: () => void;
}

/**
 * カレンダー: サイドバー
 */
export const DateInputSiderBar: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <Title>
        {i18next.t("paymentRequests.invoiceDetail.calendar.dateSelection")}
      </Title>
      <Items>
        <DateButton onClick={props.handleClickTodayButton}>
          {i18next.t("paymentRequests.invoiceDetail.calendar.today")}
        </DateButton>
        {props.lastMonthShortcut && (
          <DateButton onClick={props.handleClickEndOfLastMonthButton}>
            {i18next.t("paymentRequests.invoiceDetail.calendar.endOfLastMonth")}
          </DateButton>
        )}
        <DateButton onClick={props.handleClickEndOfMonthButton}>
          {i18next.t("paymentRequests.invoiceDetail.calendar.endOfMonth")}
        </DateButton>
        {props.nextMonthShortcut && (
          <DateButton onClick={props.handleClickEndOfNextMonthButton}>
            {i18next.t("paymentRequests.invoiceDetail.calendar.endOfNextMonth")}
          </DateButton>
        )}
      </Items>

      {props.clearable && (
        <ClearButton onClick={props.handleClickClearButton}>
          {i18next.t("paymentRequests.invoiceDetail.calendar.clear")}
        </ClearButton>
      )}
    </Wrapper>
  );
};
