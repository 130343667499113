import { BaseDateInput } from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput";
import {
  DateInputMinDate,
  DateInputPlacement,
} from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput/interface";
import { SimpleDateInputContainer } from "applications/kernels/organizations/design-system/components/DateInput/SimpleDateInput/components/Container";
import React from "react";
import { useHooks } from "./hooks";

interface Props {
  /** 日付 */
  value: string | undefined;
  /** 非選択状態か */
  disabled: boolean;
  /** 吹き出しの表示位置 */
  placement: DateInputPlacement;
  /** クリア可能か */
  clearable: boolean;
  /** 選択可能な最小の日付 */
  minDate?: DateInputMinDate;
  /** 日付を変更した */
  onChange: (value: string) => void;
}

/**
 * カレンダーフォーム
 */
export const DateInput: React.FC<Props> = (p): JSX.Element => {
  const { date, handleChange, handleClear } = useHooks(p.value, p.onChange);

  /**
   * カスタムレイアウト: カレンダーの中身
   */
  const customContainer = (calendar: JSX.Element): JSX.Element => (
    <SimpleDateInputContainer children={calendar} />
  );

  return (
    <BaseDateInput
      date={date}
      disabled={p.disabled}
      placement={p.placement}
      clearable={p.clearable}
      customContainer={customContainer}
      minDate={p.minDate}
      handleChange={handleChange}
      handleClear={handleClear}
    />
  );
};
