import React from "react";
import styled from "styled-components";

interface ButtonProps {
  hidden?: boolean;
}
const Button = styled.div<ButtonProps>`
  position: absolute;
  line-height: 1;
  right: 0;
  top: 0;
  bottom: 0;
  height: 14px;
  margin-top: 10px;
  margin-right: 6px;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  ${(p): string => (p.hidden ? "visibility: hidden" : "")}
`;

interface Props {
  disabled?: boolean;
  /** styled-componentsでラップするため使用 */
  className?: string;
  onClick: () => void;
}

/**
 * クリアボタン
 */
export const ClearButton: React.FC<Props> = (props) => (
  <Button
    hidden={props.disabled}
    onClick={props.onClick}
    className={`fa fa-times-circle${
      props.className ? ` ${props.className}` : ""
    }`}
  />
);
