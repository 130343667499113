import { getDate } from "date-fns";
import React from "react";
import styled from "styled-components";
import { useHooks } from "./hooks";

interface WrapperProps {
  selected: boolean;
  isHoliday: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  ${(p): string => (p.isHoliday ? "color: #EE6400;" : "")}
  ${(p): string => (p.selected ? "color: #ffffff;" : "")}
`;

interface Props {
  /** 選択中の日付 */
  selectedDate: Date | undefined;
  /** カレンダーの日付 */
  calendarDay: Date;
  /** 祝日一覧 */
  publicHolidays: string[];
}

/**
 * カレンダー: 日付
 */
export const DateInputDay: React.FC<Props> = (props) => {
  const { selected, isHoliday } = useHooks(
    props.selectedDate,
    props.calendarDay,
    props.publicHolidays,
  );

  return (
    <Wrapper selected={selected} isHoliday={isHoliday}>
      {getDate(props.calendarDay)}
    </Wrapper>
  );
};
