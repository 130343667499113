import { ReorganizationsChangeEmployeesTableMemberships } from "applications/kernels/organizations/reorganizations/compornents/employee/EmployeesTable/components/Memberships";
import i18next from "i18next";
import React from "react";
import styled from "styled-components";
import {
  ReorganizationsChangesEmployeesIndexDetailMembershipResponse,
  ReorganizationsChangesEmployeesIndexDetailResponse,
} from "utilities/api/responses/kernels/organizations/reorganizations/changes/EmployeesIndexResponse";

const NoChanges = styled.div`
  color: #aeaeae;
`;

interface UseReturn {
  getEmployee: (
    employee: ReorganizationsChangesEmployeesIndexDetailResponse,
  ) => JSX.Element;
  getMembership: (
    memberships: ReorganizationsChangesEmployeesIndexDetailMembershipResponse[],
  ) => JSX.Element;
  getChangedMembership: (
    memberships: ReorganizationsChangesEmployeesIndexDetailMembershipResponse[],
  ) => JSX.Element;
  getButton: (
    id: string,
    canReverse: boolean,
    onClick: (id: string) => void,
  ) => JSX.Element;
}

/**
 * テーブルカラム: 組織マスタ従業員一覧テーブル
 */
export const useColumns = (): UseReturn => {
  /**
   * 変更なし
   */
  const getNoChanges = (): JSX.Element => (
    <NoChanges>
      {i18next.t("reorganizations.changeEmployees.table.columns.noChanges")}
    </NoChanges>
  );

  /**
   * 所属部署なし
   */
  const getNoMemberships = (): JSX.Element => (
    <NoChanges>
      {i18next.t("reorganizations.changeEmployees.table.columns.noMemberships")}
    </NoChanges>
  );

  /**
   * カラム: 従業員
   */
  const getEmployee = (
    employee: ReorganizationsChangesEmployeesIndexDetailResponse,
  ): JSX.Element => {
    return (
      <>
        <div>{employee.user.name}</div>
        <div>{employee.user.email}</div>
      </>
    );
  };

  /**
   * カラム: 所属部署
   */
  const getMembership = (
    memberships: ReorganizationsChangesEmployeesIndexDetailMembershipResponse[],
  ): JSX.Element => {
    if (memberships.length === 0) return getNoMemberships();

    return (
      <ReorganizationsChangeEmployeesTableMemberships
        memberships={memberships}
      />
    );
  };

  /**
   * カラム: 変更後_所属部署
   */
  const getChangedMembership = (
    memberships: ReorganizationsChangesEmployeesIndexDetailMembershipResponse[],
  ): JSX.Element => {
    if (memberships.length === 0) return getNoChanges();

    return (
      <ReorganizationsChangeEmployeesTableMemberships
        memberships={memberships}
      />
    );
  };

  /**
   * カラム: ボタン
   */
  const getButton = (
    id: string,
    canReverse: boolean,
    onClick: (id: string) => void,
  ): JSX.Element => {
    const disabled = !canReverse ? " disabled" : "";
    const className = `btn btn-outline btn-danger${disabled}`;
    return (
      <button
        className={className}
        onClick={(e): void => {
          e.stopPropagation();
          if (!canReverse) return;
          onClick(id);
        }}
      >
        {i18next.t("reorganizations.changeEmployees.actions.reverse")}
      </button>
    );
  };

  return {
    getEmployee,
    getMembership,
    getChangedMembership,
    getButton,
  };
};
