import { getMonth, getYear } from "date-fns";

interface UseReturn {
  year: number;
  monthName: string;
}

/**
 * ロジック: カレンダーヘッダー
 */
export const useHooks = (headerDate: Date | number): UseReturn => {
  const months = [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ];
  const year = getYear(headerDate);
  const monthName = months[getMonth(headerDate)];

  return {
    year,
    monthName,
  };
};
