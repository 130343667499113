import React from "react";
import styled from "styled-components";
import { useHooks } from "./hooks";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
`;
const Month = styled.span`
  font-weight: 700;
  margin-right: 4px;
`;
interface MoveMonthButtonProps {
  next: boolean;
  disabled?: boolean;
}
const MoveMonthButton = styled.button<MoveMonthButtonProps>`
  border: none;
  background-color: transparent;
  ${(p): string => (p.disabled ? "opacity: 0.3;" : "")}

  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #aeaeae;
    border-right: 2px solid #aeaeae;
    transform: rotate(${(p): string => (p.next ? "45deg" : "-135deg")});
  }
  &:hover {
    opacity: 0.6;
  }
`;

interface Props {
  headerDate: Date | number;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  increaseMonth: () => void;
  decreaseMonth: () => void;
}

/**
 * カレンダー: ヘッダー
 */
export const DateInputHeader: React.FC<Props> = (props) => {
  const { year, monthName } = useHooks(props.headerDate);

  return (
    <Wrapper>
      <MoveMonthButton
        onClick={props.decreaseMonth}
        disabled={props.prevMonthButtonDisabled}
        next={false}
      />
      <div>
        <Month>{monthName}</Month>({year})
      </div>
      <MoveMonthButton
        onClick={props.increaseMonth}
        disabled={props.nextMonthButtonDisabled}
        next={true}
      />
    </Wrapper>
  );
};
