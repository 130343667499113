import { css } from "styled-components";

/**
 * CSSスタイルの上書き: カレンダー_日付
 */
export const DateInputDayCSS = css`
  // 当月以外の日付の色を薄くする
  .react-datepicker__day--outside-month {
    opacity: 0.3;
  }
  .react-datepicker__day-names {
    font-weight: 700;
  }

  // 土日の色を変える
  .react-datepicker__day-name:first-child,
  .datepicker__day--sunday {
    color: #ef5350;
  }
  .react-datepicker__day-name:last-child,
  .datepicker__day--saturday {
    color: #4299f7;
  }

  // 選択してる日付の背景色
  .react-datepicker {
    &__day {
      &--highlighted,
      &--selected,
      &--in-selecting-range,
      &--in-range,
      &--keyboard-selected {
        background-color: #000000;
      }
    }
  }
`;
