import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  padding: 0 10px 10px;
  font-size: 1.3rem;
  font-weight: 400;
`;

interface Props {
  children: JSX.Element;
}

/**
 * カレンダー: 中身
 */
export const SimpleDateInputContainer: React.FC<Props> = (props) => {
  return <Wrapper>{props.children}</Wrapper>;
};
