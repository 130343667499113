/**
 * 組織変更予約: ステータスによる機能制限
 * draft: 編集中
 * scheduled: 予約済み
 * queued: 反映待ち
 * running: 反映中
 * succeeded: 反映済み
 * verifying: 反映エラー（要確認）
 * failed: 反映エラー
 */
export class ReorganizationsChangeStatusPermission {
  constructor(private readonly status: string) {
    this.status = status;
  }

  /**
   * 予約、即時反映が可能か
   */
  canApply(): boolean {
    return this.status === "draft";
  }

  /**
   * 編集中であるか。変更内容の追加、編集、削除が可能か
   */
  isEditing(): boolean {
    return this.status === "draft";
  }

  /**
   * 予約の取り消しが可能か
   */
  canCancelReserve(): boolean {
    return this.status === "scheduled";
  }
}
