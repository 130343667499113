import { DateInputSiderBar } from "applications/kernels/organizations/design-system/components/DateInput/SideBarDateInput/components/SideBar";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
`;
const Callendar = styled.div`
  position: relative;
  padding: 0 10px 10px;
  font-size: 1.3rem;
  font-weight: 400;
`;

interface Props {
  clearable: boolean;
  lastMonthShortcut?: boolean;
  nextMonthShortcut?: boolean;
  children: JSX.Element;
  handleClickTodayButton: () => void;
  handleClickEndOfMonthButton: () => void;
  handleClickEndOfLastMonthButton: () => void;
  handleClickEndOfNextMonthButton: () => void;
  handleClickClearButton: () => void;
}

/**
 * カレンダー: 中身
 */
export const SideBarDateInputContainer: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <DateInputSiderBar
        clearable={props.clearable}
        lastMonthShortcut={props.lastMonthShortcut}
        nextMonthShortcut={props.nextMonthShortcut}
        handleClickTodayButton={props.handleClickTodayButton}
        handleClickEndOfMonthButton={props.handleClickEndOfMonthButton}
        handleClickEndOfLastMonthButton={props.handleClickEndOfLastMonthButton}
        handleClickEndOfNextMonthButton={props.handleClickEndOfNextMonthButton}
        handleClickClearButton={props.handleClickClearButton}
      />
      <Callendar>{props.children}</Callendar>
    </Wrapper>
  );
};
