import { DateInputMinDate } from "./interface";

/**
 * 日付フォーマット
 */
export const DATE_FORMAT_DATE_FNS = "yyyy/M/d";

interface UseReturn {
  minDate: Date | undefined;
}

/**
 * ロジック: ベースカレンダー
 */
export const useHooks = (
  propsMinDate: DateInputMinDate | undefined,
): UseReturn => {
  /**
   * 選択可能な最小の日付を返す
   */
  const minDate = (): Date | undefined => {
    if (propsMinDate === "today") {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      return today;
    }
    return undefined;
  };

  return {
    minDate: minDate(),
  };
};
