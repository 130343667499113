import { css } from "styled-components";

/**
 * CSSスタイルの上書き: カレンダー_吹き出し部分
 */
export const DateInputPopperCSS = css`
  .react-datepicker-popper[data-placement="top-end"] .datepicker-calendar {
    &:before {
      bottom: -24px;
      border-top: 12px solid #fff;
      left: 80%;
    }

    &:after {
      bottom: -28px;
      border-top: 14px solid #606060;
      left: 80%;
    }
  }

  .react-datepicker-popper[data-placement="bottom-end"] .datepicker-calendar {
    &:before {
      top: -24px;
      border-bottom: 12px solid #fff;
      left: 80%;
    }

    &:after {
      top: -28px;
      border-bottom: 14px solid #606060;
      left: 80%;
    }
  }

  .react-datepicker-popper[data-placement="top-start"] .datepicker-calendar {
    &:before {
      bottom: -24px;
      border-top: 12px solid #fff;
      left: 20%;
    }

    &:after {
      bottom: -28px;
      border-top: 14px solid #606060;
      left: 20%;
    }
  }

  .react-datepicker-popper[data-placement="bottom-start"] .datepicker-calendar {
    &:before {
      top: -24px;
      border-bottom: 12px solid #fff;
      left: 20%;
    }

    &:after {
      top: -28px;
      border-bottom: 14px solid #606060;
      left: 20%;
    }
  }

  .react-datepicker-popper[data-placement="top"] .datepicker-calendar {
    &:before {
      bottom: -24px;
      border-top: 12px solid #fff;
      left: 50%;
    }

    &:after {
      bottom: -28px;
      border-top: 14px solid #606060;
      left: 50%;
    }
  }

  .react-datepicker-popper[data-placement="bottom"] .datepicker-calendar {
    &:before {
      top: -24px;
      border-bottom: 12px solid #fff;
      left: 50%;
    }

    &:after {
      top: -28px;
      border-bottom: 14px solid #606060;
      left: 50%;
    }
  }
`;
