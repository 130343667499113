import flash from 'utilities/flash';
import { ApiFunc, ApiFuncWithRequest, ApiFuncWithoutRequest } from "../actions/AsyncAction";
import { RequestObject, getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';
import { useCallback, useRef, useState } from "react";

function callApi<Res, Req extends RequestObject>(apiFunc: ApiFunc<Res, Req>, req?: Req): Promise<Res> {
  if (req) {
    return (apiFunc as ApiFuncWithRequest<Res, Req>)(snakecaseKeys(req));
  }

  return (apiFunc as ApiFuncWithoutRequest<Res>)();
}

export interface ErrorResponse extends XMLHttpRequest {
  responseJSON: {
    message: string;
  };
}

export interface FetcherState<Res, Req> {
  isLoading: boolean;
  response: undefined | Res;
  errorMessage: undefined | string;
  fetch: (req?: Req) => Promise<Res>;
}

export interface FetcherOptions<Res, Req> {
  /**
   * レスポンスstateの初期値
   */
  initialResponse?: Res,

  /**
   * 実行時にRequestが渡されなかった場合の初期値
   */
  defaultParams?: Req,

  /**
   * エラー時にflashを表示するかどうか(デフォルトはfalse)
   */
  flashOnError?: boolean,

  /**
   * promise rejectionをcatchした場合に、上位のunhandledRejectionハンドラに投げるかどうか(デフォルトはfalse)
   *
   * このフラグがtrueの場合、上位のunhandledRejectionハンドラでエラーをキャッチする必要がある。
   * もし関数を渡した場合、その関数がtrueを返した場合のみ上位のunhandledRejectionハンドラに投げる。
   */
  throwsRejection?: boolean | ((catchingError: unknown) => boolean),
}

export function useFetcher<
  Res,
  Req extends RequestObject,
>(
  apiFunc: ApiFunc<Res, Req>,
  options: FetcherOptions<Res, Req> = {},
): FetcherState<Res, Req> {
  const {
    initialResponse,
    defaultParams,
    flashOnError = false,
    throwsRejection = false,
  } = options;

  const flashOnErrorRef = useRef(flashOnError);
  const throwsRejectionRef = useRef(throwsRejection);

  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<Res | undefined>(initialResponse);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const finalize = useCallback((promise) => {
    promise
      .then((res: Res) => {
        setResponse(res);
      })
      .catch((e: ErrorResponse) => {
        setResponse(undefined);

        const message = getMessageFromResponse(e);
        setErrorMessage(getMessageFromResponse(e));

        // パラメータでtrueが渡されていた場合はflashする
        if (flashOnErrorRef.current) {
          flash.error(message);
        }

        // パラメータでtrueが渡されていた場合はunhandledRejectionを投げる
        if (throwsRejectionRef.current) {
          // 例外を throw すると reject されるので先に isLoading の状態を戻す。
          setIsLoading(false);

          if (typeof throwsRejectionRef.current === 'function') {
            if (throwsRejectionRef.current(e)) {
              throw e;
            }
          } else {
            throw e;
          }
        }
      })
      // finally
      .then(() => {
        setIsLoading(false);
      });

    return promise;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = useCallback((req = undefined) => {
    if (!isLoading) {
      setIsLoading(true);
      setErrorMessage(undefined);

      return finalize(callApi(apiFunc, req ?? defaultParams));
    }

    return Promise.resolve(response);
  }, [apiFunc, finalize, isLoading, defaultParams, response]);

  return {
    isLoading,
    response,
    errorMessage,
    fetch,
  };
}
