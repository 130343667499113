import { ClearButton } from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput/components/ClearButton";
import React, { forwardRef, InputHTMLAttributes } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { useHooks } from "./hooks";

const ClearButtonElement = styled(ClearButton)`
  z-index: 3;
`;

interface Props extends InputHTMLAttributes<FormControl & HTMLElement> {
  clearable: boolean;
  onClear: () => void;
}

/**
 * カレンダー: 入力フォーム
 * clearableな場合、値が入った後にクリアアイコンを表示
 */
export const DateInputInput = forwardRef<FormControl & HTMLElement, Props>(
  ({ onChange, onClear, ...props }, ref) => {
    const { value, handleChange, handleBlur, handleClear } = useHooks(
      props.value,
      onClear,
      onChange,
    );

    return (
      <InputGroup>
        <InputGroup.Addon onClick={props.onClick}>
          <i className="fas fa-calendar-alt" />
        </InputGroup.Addon>
        {props.clearable ? (
          <ClearButtonElement onClick={handleClear} disabled={props.disabled} />
        ) : null}
        <FormControl
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={props.onClick}
          ref={ref}
          value={value}
          disabled={props.disabled}
        />
      </InputGroup>
    );
  },
);
