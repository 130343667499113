import { DateInputDayCSS } from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput/css/day";
import React from "react";
import styled, { css } from "styled-components";

/**
 * CSSスタイルの上書き: カレンダー_ヘッダー
 */
const DateInputHeaderCSS = css`
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }
`;
/**
 * CSSスタイルの上書き: カレンダー_吹き出しの三角形の部分
 */
const DateInputPopperTailCSS = css`
  &:before {
    content: "";
    position: absolute;
    margin-left: -15px;
    border: 12px solid transparent;
    z-index: 2;
  }
  &:after {
    content: "";
    position: absolute;
    margin-left: -17px;
    border: 14px solid transparent;
    z-index: 1;
  }
`;
const Wrapper = styled.div`
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック",
    "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Sans",
    "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  border-radius: 16px !important;
  border-color: #606060;
  box-shadow: 2px 2px 4px 0px #00000040;

  ${DateInputPopperTailCSS}
  ${DateInputHeaderCSS}
  ${DateInputDayCSS}
`;

interface Props {
  className: string;
  children: JSX.Element;
}

/**
 * カレンダー: 中身
 */
export const DateInputContainer: React.FC<Props> = (props) => {
  return <Wrapper className={props.className}>{props.children}</Wrapper>;
};
