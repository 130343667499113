import moment from "moment";
import { useCallback, useEffect, useState } from "react";

interface UseReturn {
  date: Date | undefined;
  handleChange: (value: Date) => void;
  handleClear: () => void;
}

/**
 * ロジック: カレンダーフォーム
 */
export const useHooks = (
  value: string | undefined,
  onChange: (date: string) => void,
): UseReturn => {
  const [date, setDate] = useState<Date | undefined>();

  /**
   * 日付の変更をした
   */
  const handleChange = useCallback(
    (d: Date) => {
      setDate(d);
      onChange(d.toISOString());
    },
    [onChange],
  );

  /**
   * クリアをした
   */
  const handleClear = useCallback(() => {
    onChange("");
    setDate(undefined);
  }, [onChange]);

  /**
   * 引数で渡された日付が変更された
   * ISO-8601 形式を検証し、OKならInputに反映する
   */
  useEffect(() => {
    const d = moment(value ?? "", moment.ISO_8601, true);
    setDate(d.isValid() ? d.toDate() : undefined);
  }, [value]);

  return {
    date,
    handleChange,
    handleClear,
  };
};
