import { DateInputContainer } from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput/components/Container";
import { DateInputDay } from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput/components/Day";
import { DateInputHeader } from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput/components/Header";
import { DateInputInput } from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput/components/Input";
import { DateInputPopperCSS } from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput/css/popper";
import {
  DateInputMinDate,
  DateInputPlacement,
} from "applications/kernels/organizations/design-system/components/DateInput/BaseDateInput/interface";
import ja from "date-fns/locale/ja";
import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import styled from "styled-components";
import { getDatePickerClassName } from "utilities/Utils";
import { DATE_FORMAT_DATE_FNS, useHooks } from "./hooks";

const jaLocale = {
  ...ja,
  options: {
    ...ja.options,
    weekStartsOn: 0,
  },
};
registerLocale("ja", jaLocale);

interface CustomContainerProps {
  className: string;
  children: JSX.Element;
}

const Wrapper = styled.div`
  ${DateInputPopperCSS}
`;

interface Props {
  /** ref */
  ref?: React.MutableRefObject<ReactDatePicker>;
  /** 日付 */
  date: Date | undefined;
  /** 非選択状態か */
  disabled: boolean;
  /** 吹き出しの表示位置 */
  placement: DateInputPlacement;
  /** クリア可能か */
  clearable: boolean;
  /** カスタムレイアウト */
  customContainer: (calendar: JSX.Element) => JSX.Element;
  /** 選択可能な日付 */
  minDate?: DateInputMinDate;
  /** 日付を変更した */
  handleChange: (value: Date) => void;
  /** クリアした */
  handleClear: () => void;
}

/**
 * カレンダーフォーム: ベース
 */
export const BaseDateInput = React.forwardRef(
  (props: Props, ref: ReactDatePicker) => {
    const { minDate } = useHooks(props.minDate);

    /**
     * UserPreferences: 祝日の一覧
     */
    const publicHolidays = userPreferences.preference.publicHolidays;

    /**
     * カスタムレイアウト: ヘッダー
     */
    const customHeader = ({
      date: headerDate,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    }): JSX.Element => (
      <DateInputHeader
        headerDate={headerDate}
        prevMonthButtonDisabled={prevMonthButtonDisabled}
        nextMonthButtonDisabled={nextMonthButtonDisabled}
        decreaseMonth={decreaseMonth}
        increaseMonth={increaseMonth}
      />
    );

    /**
     * カスタムレイアウト: カレンダーの日付
     */
    const dayContents = (_, calendarDay: Date): JSX.Element => (
      <DateInputDay
        selectedDate={props.date}
        calendarDay={calendarDay}
        publicHolidays={publicHolidays}
      />
    );

    /**
     * カスタムレイアウト: カレンダーの中身
     */
    const customContainer = ({
      className,
      children,
    }: CustomContainerProps): JSX.Element => (
      <DateInputContainer className={className}>
        {props.customContainer(children)}
      </DateInputContainer>
    );

    return (
      <Wrapper>
        <ReactDatePicker
          ref={ref}
          dateFormat={DATE_FORMAT_DATE_FNS}
          selected={props.date}
          popperPlacement={props.placement}
          locale="ja"
          calendarClassName="datepicker-calendar"
          dayClassName={getDatePickerClassName}
          disabled={props.disabled}
          customInput={
            <DateInputInput
              clearable={props.clearable}
              onClear={props.handleClear}
              disabled={props.disabled}
            />
          }
          minDate={minDate}
          renderCustomHeader={customHeader}
          renderDayContents={dayContents}
          calendarContainer={customContainer}
          onChange={props.handleChange}
        />
      </Wrapper>
    );
  },
);
