interface UseReturn {
  selected: boolean;
  isHoliday: boolean;
}

/**
 * ロジック: カレンダー日付
 */
export const useHooks = (
  date: Date | undefined,
  calendarDay: Date,
  publicHolidays: string[],
): UseReturn => {
  /**
   * 日付が一致するか
   */
  const isEqualDate = (date1: Date, date2: Date): boolean =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  /**
   * 選択中であるか
   */
  const selected = !!date && isEqualDate(calendarDay, date);

  /**
   * 祝日であるか
   */
  const isHoliday = publicHolidays.some((holiday) => {
    const publicHoliday = new Date(holiday);
    return isEqualDate(publicHoliday, calendarDay);
  });

  return {
    selected,
    isHoliday,
  };
};
