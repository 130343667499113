import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";

const DATE_FORMAT_MOMENT = "YYYY/M/D";

interface UseReturn {
  value: string;
  handleChange: (e: React.ChangeEvent<FormControl & HTMLInputElement>) => void;
  handleBlur: () => void;
  handleClear: () => void;
}

/**
 * ロジック: カレンダー入力フォーム
 */
export const useHooks = (
  propsValue: string | number | string[] | undefined,
  onClear: () => void,
  onChange?: (e: React.ChangeEvent<FormControl & HTMLInputElement>) => void,
): UseReturn => {
  const [value, setValue] = useState<string>("");

  /**
   * 変更をした
   */
  const handleChange = useCallback(
    (e: React.ChangeEvent<FormControl & HTMLInputElement>): void => {
      // 指定されたformat形式を検証し、OKなら反映する
      const date = moment(e.target.value, DATE_FORMAT_MOMENT, true);
      setValue(e.target.value);
      return date.isValid() ? onChange?.(e) : void 0;
    },
    [onChange],
  );

  /**
   * クリアを押した
   */
  const handleClear = useCallback(() => {
    setValue("");
    return onClear();
  }, [onClear]);

  /**
   * フォーカスが外れた
   */
  const handleBlur = useCallback(() => {
    // 空だったら入力値(this.value)が不正だったとしても、入力途中の状態だとして保持する
    if (propsValue !== undefined && propsValue !== "") {
      setValue(String(propsValue));
    }
  }, [propsValue]);

  /**
   * 引数で渡された値が変わった
   */
  useEffect(() => {
    setValue(String(propsValue ?? ""));
  }, [propsValue]);

  return {
    value,
    handleChange,
    handleBlur,
    handleClear,
  };
};
